<template>
    <div class="member-detail-info-box">
        <div class="container">
            <!-- 导航 -->
            <a href="javascript:;" class="member-detail-nav"
                @click="$router.go(-1)">
                <i class="el-icon-back member-detail-nav-icon"></i>
                <span class="member-detail-nav-text">返回</span>
            </a>
            <!-- 个人信息 -->
            <div class="member-detail-info">
				<!-- 头像 -->
                <el-image 
					v-if="personal_datar.avatar"
                    :src="personal_datar.avatar"
                    fit="cover"
                    class="member-detail-info-avatar">
                    <!-- 加载中样式 -->
                    <template v-slot:placeholder>
                        <div class="image-slot">
                            <i class="el-icon-loading"></i>
                        </div>
                    </template>
                    <!-- 加载失败样式 -->
                    <template v-slot:error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
                <!-- 昵称 -->
                <div class="member-detail-info-nick">{{personal_datar.name}}</div>
                <!-- 地址 -->
                <div class="member-detail-info-address">{{personal_datar.address}}</div>
                <!-- 年龄、性别 -->
                <div class="member-detail-info-age-sex">
                    <span>年龄：{{personal_datar.age}}</span>
                    <span>性别：{{personal_datar.gender==0?"女":"男"}}</span>
                </div>
                <!-- 个性签名 -->
                <div class="member-detail-info-signature">{{personal_datar.type}}</div>
                <!-- 标签 -->
                <div class="member-detail-info-tabs">
                    <span v-for="item in personal_datar.style"
                        :key="item.id">
                        {{item}}
                    </span>
                </div>
            </div>
            <!-- 作品 -->
            <div class="member-detail-works-title">作品 / Works</div>
            <!-- 内容 -->
            <div class="member-detail-works-list">
                <div v-for="item in personal_datar.works"
                    :key="item.id">
                    <el-image
                        :src="item"
                        fit="cover"
                        @click="handleDialog(item, true)"
                        lazy
                        class="member-detail-works-item">
                        <!-- 加载中样式 -->
                        <template v-slot:placeholder>
                            <div class="image-slot">
                                <i class="el-icon-loading"></i>
                            </div>
                        </template>
                        <!-- 加载失败样式 -->
                        <template v-slot:error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                </div>
            </div>
            <!-- 服务类型 -->
            <div class="member-detail-works-title">服务类型 / Service Type</div>
            <!-- 内容 -->
            <div class="member-detail-type">
                <detail-more
                    v-for="(item, index) in serviceList"
                    :key="index"
                    :item-data="item">
                    <template>
                        <div class="member-detail-item">
                            <!-- 标题 -->
                            <div class="member-detail-item-title">
                                {{item.name}}
                            </div>
                            <!-- 副标题 -->
                            <div class="member-detail-item-subtitle">
                                服务描述：{{item.sketch}}
                            </div>
                            <!-- 价格 -->
                            <div class="member-detail-item-price">
                                ¥ {{item.price}}元/{{item.unit}}
                            </div>
                            <!-- 跳转按钮 -->
                            <div>
                                <router-link :to="{
                                        name: serviceRouter,
                                        query: {
                                            id: item.id,
                                            userID: $route.query.id
                                        }
                                    }"
                                    class="member-detail-item-more">
                                    查看详情
                                </router-link>
                            </div>
                        </div>
                    </template>
                </detail-more>
            </div>
            <!-- 图片放大模态框 -->
            <el-dialog
                :visible.sync="dialogVisible"
                width="40%"
                :before-close="() => handleDialog('', false)"
                class="member-detail-info-dialog">
                <img :src="dialog_img" alt="">
                <el-button
                    type="text"
                    @click="handleDialog('', false)"
                    class="member-detail-info-dialog-close">
                    <i class="el-icon-close"></i>
                </el-button>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    data() {
        return {
            dialog_img: "",
            dialogVisible: false,
			personal_datar:{},
        }
    },
    props: {
        serviceRouter: String
    },
    computed: mapState({
        personal_data: state => state.goodsDetail.personal_data,
        serviceList: state => state.goodsDetail.serviceList
    }),
	watch:{
		personal_data(){
			this.personal_datar = this.personal_data
		}
	},
	mounted() {
		this.personal_datar = this.personal_data
	},
    components: {
        detailMore: () => import("@/components/detailMore"),
    },
    methods: {
        handleDialog(item, on_off){
            this.dialog_img = item;
            this.dialogVisible = on_off;
        }
    },
}
</script>

<style scoped>
.member-detail-info-box{
    background-color: #fafafa;
}
.member-detail-info-box > .container {
    position: relative;
}
.member-detail-nav {
    position: absolute;
    top: 20px;
    color: #8d9afc;
    z-index: 10;
}
.member-detail-nav-icon {
    font-size: 18px;
}
.member-detail-nav-text {
    margin-left: 10px;
    font-size: 18px;
}

/* 个人信息 */
.member-detail-info{
    position: relative;
    top: -50px;
    text-align: center;
}
.member-detail-info>div+div{
    margin-top: 10px;
}
/* 头像 */
.member-detail-info-avatar{
    width: 100px;
    height: 100px;
    border: 2px solid #ffd86b;
    border-radius: 50%;
}
/* 昵称 */
/* .member-detail-info-nick{} */
/* 地址 */
.member-detail-info-address{
    color: #909399;
}
/* 年龄性别 */
.member-detail-info-age-sex>span+span{
    margin-left: 20px;
}
/* 个性签名 */
.member-detail-info-age-sex>span,
.member-detail-info-signature{
    color: #303133;
    font-size: 18px;
}
/* 标签 */
.member-detail-info-tabs{
    display: flex;
    justify-content: center;
    align-items: center;
}
.member-detail-info-tabs>span{
    padding: 0 10px;
    background-color: #E6A23C;
    color: #fff;
    border-radius: 20px;
}
/* 作品 */
.member-detail-works-title{
    font-size: 20px;
    margin-bottom: 20px;
}
/* 作品标签页 */
.member-detail-works-tabs{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.member-detail-works-tabs>li+li{
    margin-left: 10px;
}
.member-detail-works-tabs>li>a{
    display: inline-block;
    padding: 10px 30px;
    background-color: #8d9afc;
    font-size: 16px;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.member-detail-works-tabs>li.active-tab>a{
    background-color: #fff;
    color: #303133;
}
/* 作品内容 */
.member-detail-works-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    padding: 80px;
    background-color: #fff;
}
.member-detail-works-list>div{
    position: relative;
    width: 25%;
    overflow: hidden;
}
.member-detail-works-list>div::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.member-detail-works-item{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 97%;
    height: 97%;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
}

/* 服务类型 */
.member-detail-type{
    margin-bottom: 50px;
    padding: 50px;
    background-color: #fff;
}
.member-detail-type > div + div{
    margin-top: 20px;
}
.member-detail-item{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 10px;
}
.member-detail-item-title{
    font-size: 16px;
    font-weight: bold;
}
.member-detail-item-subtitle{
    flex: 1;
    color: #909399
}
.member-detail-item-price{
    font-size: 20px;
    color: #E6A23C;
}
.member-detail-item-more{
    display: inline-block;
    padding: 5px 20px;
    background-color: #8d9afc;
    border-radius: 30px;
    color: #fff;
}

/* 图片模态框 */
.member-detail-info-dialog>>>.el-dialog{
    border-radius: 6px;
    overflow: hidden;
}
.member-detail-info-dialog>>>.el-dialog__header{
    display: none;
}
.member-detail-info-dialog>>>.el-dialog__body{
    padding: 0;
}
.member-detail-info-dialog>>>img{
    width: 100%;
}
.member-detail-info-dialog>>>.member-detail-info-dialog-close{
    position: absolute;
    top: 10px;
    right: 20px;
}
</style>